require("socicon");
require("dompurify");
require("./node_modules/open-iconic/font/css/open-iconic-bootstrap.min.css");
require("line-awesome/dist/line-awesome/css/line-awesome.css");
require("@fortawesome/fontawesome-free");
require("@fortawesome/fontawesome-free/js/all.min.js");
require("@fortawesome/fontawesome-free/css/all.min.css");

import "./node_modules/bootstrap/dist/css/bootstrap.min.css";

import './Theme/css/Components/AzureMap.css';
import './Theme/css/Components/SidebarMenu.css';
import './Theme/css/Components/Portlet.css';

import "./Theme/Css/styles.css";
import "./Theme/Css/403-styles.css";
import "./Theme/Css/error-styles.css";
import "./Theme/Css/Callout.css";

import "./Theme/Pages/wizard.css";
import "./Theme/Pages/pageNotFound.css";

// JS Libraries

import * as $ from "jquery";
window["jQuery"] = $;

import "bootstrap";
import * as Cookies from 'js-cookie';

namespace SkySelfServiceClient {
    class Main {

        constructor() {
            let self = this;
        }

        public AddCookie(id: string, value: string) {
            Cookies.set(id, value, { expires: 5 } );
        }

        public DeleteCookie(id: string) {
            Cookies.remove(id);
        }

        public GetCookie(id: string): string {
            return Cookies.get(id);
        }

        public GetClientAddress(): string {
            return $.getJSON('https://ipapi.co/json/', function (data) {
                return JSON.stringify(data, null, 2);
            });
        };
    }

    export function Load(): void {
        window['skySelfServiceClient'] = new Main();
    }
}

SkySelfServiceClient.Load();